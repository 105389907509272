<template>
  <div
    class="mx-auto w-full max-w-screen-sm md:mt-10 md:grid md:max-w-screen-md md:gap-8 lg:max-w-screen-lg xl:max-w-screen-xl"
    :class="{
      'md:grid-cols-7': $route.name !== 'info-index',
    }"
  >
    <div :class="{ 'col-span-4': $route.name !== 'info-index' }">
      <router-view></router-view>
    </div>

    <div
      class="col-span-3 space-y-8 pr-4"
      v-if="screenWidth > 768 && $route.name !== 'info-index'"
    >
      <div>
        <h2 class="mb-2 text-xl font-bold">{{ $t("info.need_help") }}</h2>

        <div class="space-y-4">
          <router-link
            class="flex items-center rounded-lg bg-brand-navblue p-4 text-white"
            :to="route.path"
            v-for="route in routes"
            :key="route.translationKey"
          >
            <component
              :is="route.icon"
              class="mr-8 w-10 fill-current text-white"
            ></component>

            <p class="flex-1">{{ $t(`navigation.${route.translationKey}`) }}</p>

            <icon-right class="w-6"></icon-right>
          </router-link>
        </div>
      </div>

      <div>
        <h2 class="mb-2 text-lg font-bold">
          {{ $t("info.articles_title") }}
        </h2>

        <div class="space-y-2">
          <loading v-if="loading" />

          <template v-else>
            <div v-for="article in randomArticles.items" :key="article.id">
              <router-link
                :to="{ name: 'article-single', params: { id: article.id } }"
                class="flex h-44 flex-col justify-between rounded-lg bg-black bg-opacity-50 bg-cover bg-center p-4 text-white"
                :style="{
                  backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${article.image})`,
                }"
              >
                <div>
                  <h5 class="mb-2 text-xl font-bold">{{ article.title }}</h5>
                  <p>{{ article.excerpt }}</p>
                </div>

                <span class="text-xs transition-all hover:underline">{{
                  $t("read_more")
                }}</span>
              </router-link>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconDrugcombo from "@/assets/icons/drug-pills.svg";
import IconEmergencyHelp from "@/assets/icons/emergency-help.svg";
import IconAssistance from "@/assets/icons/assistance.svg";
import IconRight from "@/assets/icons/chevron-right.svg";
import IconChemInfo from "@/assets/icons/drugsinfo.svg";
import IconArticlesBookLines from "@/assets/icons/articles-book-lines.svg";
import IconPeople from "@/assets/icons/people.svg";
import { mapGetters } from "vuex";
import { isDateInPast } from "../../utils/helpers";

export default {
  name: "Overview",

  components: {
    IconDrugcombo,
    IconEmergencyHelp,
    IconAssistance,
    IconRight,
    IconChemInfo,
    IconArticlesBookLines,
    IconPeople,
  },

  mounted() {
    window.addEventListener("resize", this.resize);
    this.fetchRandomArticles();
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },

  data() {
    return {
      screenWidth: window.innerWidth,
      routes: [
        {
          path: "/info/drugcombos",
          translationKey: "drugcombos",
          icon: "IconDrugcombo",
        },
        {
          path: "/info/chems",
          translationKey: "chems",
          icon: "IconChemInfo",
        },
        {
          path: "/info/assistance",
          translationKey: "assistance",
          icon: "IconAssistance",
        },
        {
          path: "/info/emergency",
          translationKey: "emergency",
          icon: "IconEmergencyHelp",
        },
        {
          path: "/info/articles",
          translationKey: "articles",
          icon: "IconArticlesBookLines",
        },
        {
          path: "/info/articles/chemsex",
          translationKey: "chemsex_stories",
          icon: "IconPeople",
        },
      ],
      loading: false,
    };
  },

  computed: {
    ...mapGetters(["randomArticles"]),
  },

  methods: {
    resize() {
      this.screenWidth = window.innerWidth;
    },

    async fetchRandomArticles() {
      this.loading = true;

      if (
        this.screenWidth > 768 &&
        !this.randomArticles.items.length &&
        isDateInPast(this.randomArticles.refetchInterval)
      ) {
        await this.$store.dispatch("fetchRandomArticles");
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
